.footer{
  width: 100%;
  height: 100%;
  padding-top: 10px;
  background-color: #e9ecef;
  display: block;
  margin-top: 100px;
}
.footer li{
  list-style-type: none;
}
.footer a{
  color: black;
}
.arrow_up{
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    padding: 4px;
    z-index: 99;
    color: floralwhite;
    background-color: darkgrey;
    position: fixed;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    animation: movebtn 2s ease-in-out;
    transition: all .5s ease-in-out;
}
.arrow_up:hover{
    background-color: rgb(51, 56, 61);
}
.top-to-btm{
    position: relative;
  }

  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }