.social_media_icons{
    top: 40%;
    right: 0;
    -ms-transform-origin-y: (-50%);
    position: fixed;
    z-index: 100;
}

.social_media_icons li{
    height: 30px;
    width: 30px;
    list-style-type: none;
    color: #fff;
}
.social_media_icons li:hover{
    transition: 2s;
}
.linha{
    display: block;
    width: 100%;
    height: 44px;
}
.linha span{
    display: block;
    height: 40px;
    width: 40px;
    padding:10px;
    padding-left: 14px;
    color: #fff;
}
.youtube{
    background: #ff0000;
}
.instagram{
    background: #ff4800;
}
.facebook{
    background: #3b5998;
}
.twitter{
    background: #55ace5;
}
