.main_nav{
  top:0;
  position:fixed;
  height: 80px;
  width:100%;
  transition-timing-function: ease-in;
  transition: 0.5s;
  z-index: 90;
  background-color: #fff;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.main_nav ul{
    margin-top: 30px;
    float: right;
}
.main_nav li{
  list-style: none;
  display: inline;
}
.main_nav a {
  text-decoration: none;
  padding: 10px;
  color: rgba(0,0,0,.3);
  font-weight: 300;
}
.main_nav a:hover {
  color: rgb(24, 36, 92);
}
.main_nav_bg{
  background-color: #ebf0f5;
}
.main_nav_bg a {
  color: #fff;
}
.logo{
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.nav_btns{
  width: 100%;
 float: right;
}
.nav_btns .drop-down{
  background-color: #e9ecef;
}
/*-------- resposive css --------------*/
@media (min-width:768px) and (max-width:1024px) {
  .navbar-collapse {
      background-color: #e9ecef;
  }
}
@media (min-width:480px) and (max-width:768px) 
{
  .navbar-collapse {
      background-color: #e9ecef;
  }
}
@media (max-width:480px) 
{
  .navbar-collapse {
      background-color: #e9ecef;
  }
}