.banner{
    margin-top: 80px;
    min-width: 100%;
    background-color: #18181a;
    box-shadow: 10px 2px 1px #888888;
}
/* Do not remove the .carousel class below!!! */
.carousel{
    margin-top: 80px;
    min-width: 100%;
    background-color: #18181a;
    box-shadow: 10px 2px 1px #888888;
}
.caption {
    background-color: #18181a;
    opacity: 0.6;
}