
.PortfolioMain{
    padding: 3em;
    text-align: center;
}
.imageHolder {
  position: relative;
}
.imageHolder .caption{
  position: absolute;
  bottom: 0;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  color: aliceblue;
  opacity: 0;

}
.imageHolder:hover .caption {
  display: block;
  background: rgba(3, 3, 3, 0.5);
  opacity: 2;
  -webkit-transition:all 0.45s ease-in-out;
  -moz-transition:all 0.45s ease-in-out;
  -o-transition:all 0.45s ease-in-out;
  -ms-transition:all 0.45s ease-in-out;
  transition:all 0.45s ease-in-out;
}
.caption ul{
  margin-top: 100px;
}
.caption li{
  list-style-type: none;
}
.pretitle{
  font-weight: 800;
  font-size:x-large;
  font-stretch: extra-expanded;
  color: rgb(160, 163, 122);
}
.caption .title{
  font-weight: bold;
  font-size:larger ;
  text-transform: uppercase;
  font-family: "Source Sans Pro",sans-serif;
}
.caption .subtitle{
  font-size: large;
  color:rgb(202, 202, 120);
}
h2{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size:xx-large;
  color: rgb(129, 125, 125);
}
h3{
  color: rgb(75, 72, 72);
  font-weight: 500;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
p{
  text-align: justify;
  text-indent: 2em;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.black_font{
  color: black;
}
a{
  text-decoration: none;
}