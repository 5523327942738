.bio{
    width: 100%;
    padding: 30px;
}
.bio h2, h3 {
    padding-top: 10px;
}
.bio p{
    padding-top: 20px;
}
.bio ul{
    margin-top: 10px;
    margin-left: 50px;
}
